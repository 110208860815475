/* src/styles.css */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1a1a1a;
  color: #ffffff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a:visited {
    text-decoration: none;
    color: White;
}

a:link {
    text-decoration: none;
    color: White;
}

html, body {
    min-width: 320px;  /* Set a minimum width */
    min-height: 480px; /* Set a minimum height */
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

html {
    font-size: clamp(0.5rem, 4vw, 1.15rem); /* Responsive base font size */
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}


.deck {
  position: absolute;
  width: 300px;
  height: 200px;
  will-change: transform;
  display: flex;
  align-items: center;
  justify-content: center;
  touch-action: none;
}

.deck > div {
    background-color: #333333;
    color: white;
    font-size: 2rem;
    background-size: auto 85%;
    background-repeat: no-repeat;
    background-position: center center;
    width: 45vh;
    max-width: 285px;
    height: 85vh;
    max-height: 150px;
    will-change: transform;
    border-radius: 10px;
     box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3), 0 12px 24px rgba(0, 0, 0, 0.2);
}
